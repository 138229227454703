import { isAfter, isValid, parseISO } from 'date-fns';

export function dateToUnixTimestamp(date: Date): number {
  if (date instanceof Date) {
    return Math.floor(date.getTime() / 1000);
  }
  throw new Error('Must pass a Date instance to dateToUnixTimestamp');
}

export const dateValidation = (value: any): boolean => {
  if (value === undefined || value === '') {
    return false;
  }
  if (value instanceof Date === false) {
    value = parseISO(value);
  }
  return isValid(value);
};

export const futureDate = (value: any, compareValue?: any): boolean => {
  if (value === undefined || value === '') {
    return false;
  }
  const date = parseISO(value);

  if (!isValid(date)) {
    return false;
  }

  const compareDate = compareValue ? parseISO(compareValue) : new Date();

  if (!isValid(compareDate)) {
    return false;
  }

  return isAfter(date, compareDate);
};

export const parseIsoToDate = (value: Date | string): Date => {
  if (value instanceof Date) {
    return value;
  }

  const date = parseISO(value);
  if (isValid(date) !== true) {
    throw new Error(`"${value}" is not a valid ISO8601 date string`);
  }

  return date;
};

export const formatDateToIso = (date: Date): string => {
  return date.toISOString();
};
